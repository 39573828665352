import { URLS } from 'store/constant';
import { apiSlice } from 'store/slices/api';

import { ListResponse, QueryParams } from 'types/api';
import { parseQueryParams } from 'store/slices/utils';
import {
  CreateOrgSupplier,
  OrgSupplier,
  PublicSupplier,
  UpdateOrgSupplier,
} from 'types/suppliers';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createOrgSupplier: builder.mutation<OrgSupplier, CreateOrgSupplier>({
      query: (payload) => ({
        url: URLS.ORG_SUPPLIERS,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [
        { type: 'OrgSuppliers', id: 'LIST' },
        { type: 'OrgSuppliers', id: 'PARTIAL-LIST' },
      ],
    }),
    updateOrgSupplier: builder.mutation<
      OrgSupplier,
      {
        orgSupplierId: OrgSupplier['id'];
        payload: UpdateOrgSupplier;
      }
    >({
      query: ({ orgSupplierId, payload }) => ({
        url: URLS.ORG_SUPPLIER(orgSupplierId),
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { orgSupplierId }) => [
        {
          type: 'OrgSuppliers',
          id: orgSupplierId,
        },
        { type: 'OrgSuppliers', id: 'LIST' },
        { type: 'OrgSuppliers', id: 'PARTIAL-LIST' },
      ],
    }),
    getOrgSuppliers: builder.query<OrgSupplier[], void>({
      query: () => URLS.ORG_SUPPLIERS,
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                ({ id }: OrgSupplier) => ({ type: 'OrgSuppliers', id } as const)
              ),
              { type: 'OrgSuppliers', id: 'LIST' },
            ]
          : [{ type: 'OrgSuppliers', id: 'LIST' }],
    }),
    getOrgSupplier: builder.query<
      OrgSupplier,
      {
        orgSupplierId: OrgSupplier['id'];
      }
    >({
      query: ({ orgSupplierId }) => URLS.ORG_SUPPLIER(orgSupplierId),
      providesTags: (result) =>
        result
          ? [{ type: 'OrgSuppliers', id: result.id }]
          : [{ type: 'OrgSuppliers', id: 'LIST' }],
    }),
  }),
});

export const extendedApiSliceServerSide = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getPublicSuppliersServerSide: build.query<
      ListResponse<PublicSupplier>,
      QueryParams<PublicSupplier>
    >({
      query: (queryParams) =>
        URLS.PUBLIC_SUPPLIERS_SERVER(
          parseQueryParams<PublicSupplier>(queryParams)
        ),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: PublicSupplier) =>
                  ({
                    type: 'PublicSuppliers',
                    id,
                  } as const)
              ),
              { type: 'PublicSuppliers', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'PublicSuppliers', id: 'PARTIAL-LIST' }],
    }),
    getOrgSuppliersServerSide: build.query<
      ListResponse<OrgSupplier>,
      QueryParams<OrgSupplier>
    >({
      query: (queryParams) =>
        URLS.ORG_SUPPLIERS_SERVER(parseQueryParams<OrgSupplier>(queryParams)),
      providesTags: (result) =>
        result
          ? [
              ...result.data.map(
                ({ id }: OrgSupplier) =>
                  ({
                    type: 'OrgSuppliers',
                    id,
                  } as const)
              ),
              { type: 'OrgSuppliers', id: 'PARTIAL-LIST' },
            ]
          : [{ type: 'OrgSuppliers', id: 'PARTIAL-LIST' }],
    }),
  }),
});

export const {
  useGetOrgSupplierQuery,
  useGetOrgSuppliersQuery,
  useCreateOrgSupplierMutation,
  useUpdateOrgSupplierMutation,
} = extendedApiSlice;

export const {
  useGetPublicSuppliersServerSideQuery,
  useGetOrgSuppliersServerSideQuery,
  useLazyGetOrgSuppliersServerSideQuery,
  usePrefetch,
} = extendedApiSliceServerSide;
