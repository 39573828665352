import {
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { PermissionScope } from 'types/apps';
import { PartSchemas } from 'types/partStatus';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { FieldValues } from 'react-hook-form';
import * as Yup from 'yup';
import { ReactElement } from 'react';
import { Icon as TablerIcon } from '@tabler/icons-react';
import { ColorOptions } from 'types/index';

export interface DatagridProps {
  [gridName: string]: GridInitialStatePremium | null;
}

export interface apiRefProps {
  current: GridApiPremium;
}

export enum DatagridNames {
  bomLinesLinesTableV2 = 'bomLinesLinesTableV2',
  bomsTable = 'bomsTable',
  inventoryLocations = 'inventoryLocations',
  inventoryParts = 'inventoryParts',
  inventoryShipments = 'inventoryShipments',
  inventoryStockLots = 'inventoryStockLots',
  inventoryStockLotsConsumeStockOld = 'inventoryStockLotsConsumeStockOld',
  inventoryPartLedgersTab = 'inventoryPartLedgersTab',
  inventoryPartStockLotTab = 'inventoryPartStockLotTab',
  inventoryShipmentStockLotTab = 'inventoryShipmentStockLotTab',
  inventoryShipmentLines = 'inventoryShipmentLines',
  inventoryHandling = 'inventoryHandling',
  inventoryAllocations = 'inventoryAllocations',
  handlingTasks = 'handlingTasks',
  stockLotLotLedger = 'stockLotLotLedger',
  usersTable = 'usersTable',
  partBoms = 'partBoms',
  partSourcingAgent = 'partSourcingAgent',
  partsQuotes = 'partsQuotes',
  partsSuppliersListings = 'partsSuppliersListings',
  partsDocuments = 'partsDocuments',
  partsDocumentsDescribePart = 'partsDocumentsDescribePart',
  partsWatchlist = 'partsWatchlist',
  partWatchlistSubscriptionTab = 'partWatchlistSubscriptionTab',
  detailPartsQuotes = 'detailPartsQuotes',
  purchasingSourcingRequestsTable = 'purchasingSourcingRequestsTable',
  partLibraryTable = 'partLibraryTable',
  purchasingOrders = 'purchasingOrders',
  purchasingOrdersLines = 'purchasingOrdersLines',
  purchasingOrdersStatusLines = 'purchasingOrdersStatusLines',
  productionRuns = 'productionRuns',
  productionRunParts = 'productionRunParts',
  orderRemediationAlts = 'orderRemediationAlts',
  productionRunPartsDetailAlts = 'productionRunPartsDetailAlts',
  productionRunPartsDetailBomlines = 'productionRunPartsDetailBomlines',
  productionRunPurchasingSelects = 'productionRunPurchasingSelects',
  productionRunSolicitationDialog = 'productionRunSolicitationDialog',
  partOrderHistory = 'partOrderHistory',
  matchStockLots = 'matchStockLots',
  productionRunTheoreticalShipments = 'productionRunTheoreticalShipments',
  productionRunConsumedStock = 'productionRunConsumedStock',
  partAlternatives = 'partAlternatives',
  partAlternativesDescribeParts = 'partAlternativesDescribeParts',
  shipmentSolverAdjustQuantities = 'shipmentSolverAdjustQuantities',
  shipmentSolverFailedShipments = 'shipmentSolverFailedShipments',
  shipmentSolverSuccessShipments = 'shipmentSolverSuccessShipments',
  subOrgs = 'subOrgs',
  bomPurchasingSelects = 'bomPurchasingSelects',
  invoices = 'invoices',
  suppliers = 'suppliers',
  purchaseRules = 'purchaseRules',
  processingPurchaseLines = 'processingPurchaseLines',
  allocateStockLots = 'allocateStockLots',
  purchasingDialogQuantities = 'purchasingDialogQuantities',
  allocateToProductionRunSetRequests = 'allocateToProductionRunSetRequests',
  allocateToProductionRunPreviews = 'allocateToProductionRunPreviews',
  allocateToProductionRunPreviewFailures = 'allocateToProductionRunPreviewFailures',
  multiQuoteRequests = 'multiQuoteRequests',
  bomScrubbingDuplicateParts = 'bomScrubbingDuplicateParts',
  bomScrubbingNoMatchRows = 'bomScrubbingNoMatchRows',
  bomScrubbingOverageWarningParts = 'bomScrubbingOverageWarningParts',
  bomScrubbingNoCustomIdParts = 'bomScrubbingNoCustomIdParts',
  productionRunCreateConsumeStockEvents = 'productionRunCreateConsumeStockEvents',
  purchasingOrderSnapshots = 'purchasingOrderSnapshots',
  purchasingOrderSnapshotsLines = 'purchasingOrderSnapshotsLines',
  purchasingOrderSnapshotsKitting = 'purchasingOrderSnapshotsKitting',
  purchasingOrderSnapshotLinePurchaseLines = 'purchasingOrderSnapshotLinePurchaseLines',
  purchasingOrderSnapshotLineShipments = 'purchasingOrderSnapshotLineShipments',
  documentExtractedData = 'documentExtractedData',
  // snapshotApprovalGenerateReport is not a real datagrid, but was required for generateReport functionality
  snapshotApprovalGenerateReport = 'snapshotApprovalGenerateReport',
  purchaseRequests = 'purchaseRequests',
  purchaseOrders = 'purchaseOrders',
  purchaseOrderLines = 'purchaseOrderLines',
  purchaseOrderNoPartLines = 'purchaseOrderNoPartLines',
  purchaseOrderCharges = 'purchaseOrderCharges',
  purchaseOrderPartHistory = 'purchaseOrderPartHistory',
  purchaseOrderProgramHistory = 'purchaseOrderProgramHistory',
  queryResponse = 'queryResponse',
  teams = 'teams',
  customProperties = 'customProperties',
  documentExtractors = 'documentExtractors',
}

interface BaseExtendedGridColDef extends GridColDef {
  permissionScope?: PermissionScope;
  editPermissionScope?: PermissionScope;
  inlineEdit?: boolean;
  getMutationArgs?: (data: FieldValues, params: GridRenderEditCellParams) => {};
  mutationValidation?: Yup.SchemaOf<unknown, object>;
  mutationPreSubmit?: (
    data: FieldValues,
    params: GridRenderCellParams
  ) => FieldValues;
  mutationInputElement?: ReactElement;
  getMutationDefaultValue?: (params: GridRenderEditCellParams) => unknown;
  additionalInputElementProps?: unknown;
  allowEditWhileLocked?: boolean;
  mutationGetIsDisabled?: (params: GridRenderCellParams) => boolean;
  showChart?: {
    alwaysVisible?: boolean;
    title?: string;
    description?: string;
    disabled?: boolean;
    accordionCardGroupTitle?: string;
    schemas?: PartSchemas[];
    valueOptions: {
      value: string;
      label: string;
      tooltip?: string;
      color?:
        | 'warning'
        | 'success'
        | 'error'
        | 'info'
        | 'primary'
        | 'secondary'
        | 'grey';
    }[];
  } | null;
  allowInBlended?: boolean;
  // This function is used to format the cell values for export.
  // Note: It currently only formats cells that are strings.
  exportFormatter?: (value: string) => string;
}

type InlineEditGridColDef = BaseExtendedGridColDef & {
  inlineEditRenderCell: true;
  useMutation: UseMutation<any>;
};

type NonInlineEditGridColDef = BaseExtendedGridColDef & {
  inlineEditRenderCell?: false;
};

export type ExtendedGridColDef = InlineEditGridColDef | NonInlineEditGridColDef;

export enum DatagridFilterOperatorsValues {
  '=' = '=',
  '!=' = '!=',
  '>' = '>',
  '>=' = '>=',
  '<' = '<',
  '<=' = '<=',
  is = 'is',
  not = 'not',
  after = 'after',
  before = 'before',
  onOrAfter = 'onOrAfter',
  onOrBefore = 'onOrBefore',
  contains = 'contains',
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
  isAnyOf = 'isAnyOf',
  startsWith = 'startsWith',
  endsWith = 'endsWith',
  equals = 'equals',
}

export const DatagridNumberFilterOperatorsValues = {
  '=': DatagridFilterOperatorsValues['='],
  '!=': DatagridFilterOperatorsValues['!='],
  '>': DatagridFilterOperatorsValues['>'],
  '>=': DatagridFilterOperatorsValues['>='],
  '<': DatagridFilterOperatorsValues['<'],
  '<=': DatagridFilterOperatorsValues['<='],
  isEmpty: DatagridFilterOperatorsValues.isEmpty,
  isNotEmpty: DatagridFilterOperatorsValues.isNotEmpty,
  isAnyOf: DatagridFilterOperatorsValues.isAnyOf,
};

export interface RowActionBase<T> {
  getRowIcon?: (row?: T) => TablerIcon;
  getLabel: (row?: T) => string;
  getRowTooltip?: (row?: T) => string;
  getBatchTooltip?: (selectedRowCount: number) => string;
  color?: ColorOptions;
  getColor?: (row?: T) => ColorOptions;
  onRowClick?: (row?: T) => void;
  onBatchClick?: (rows?: T[]) => void;
  permissionScope?: PermissionScope;
  disabled?: boolean;
  showInRowMenu?: boolean;
  showInBatchMenu?: boolean;
  isRowDisabled?: (row?: T) => boolean;
  isBatchDisabled?: (rows?: T[]) => boolean;
  hideRowAction?: (row?: T) => boolean;
  hideBatchAction?: (rows?: T[]) => boolean;
  trackingName?: string;
}

export type RowAction<T> = RowActionBase<T> &
  (
    | { Icon: TablerIcon; getRowIcon?: never }
    | { Icon?: never; getRowIcon: (row: T) => TablerIcon }
  );
