import { ALL_APPS, ALL_VIEWS } from 'constants/appConstants';
import MainCardWrapper from 'layout/MainLayout/MainCardWrapper';
import PermissionsRouteWrapper from 'views/pages/components/PermissionsRouteWrapper';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import FullHeightMainCardWrapper from 'layout/MainLayout/FullHeightMainCardWrapper';

const ProcurementDashboard = Loadable(
  lazy(() => import('views/procurement/Dashboard'))
);
const ProcurementInvoice = Loadable(
  lazy(() => import('views/procurement/Invoice'))
);
const ProcurementInvoices = Loadable(
  lazy(() => import('views/procurement/Invoices'))
);
const ProcurementPackingSlip = Loadable(
  lazy(() => import('views/procurement/PackingSlip'))
);
const ProcurementPackingSlips = Loadable(
  lazy(() => import('views/procurement/PackingSlips'))
);
const ProcurementPurchaseOrder = Loadable(
  lazy(() => import('views/procurement/PurchaseOrder'))
);
const ProcurementPurchaseOrders = Loadable(
  lazy(() => import('views/procurement/PurchaseOrders'))
);
const ProcurementPurchaseRequests = Loadable(
  lazy(() => import('views/procurement/PurchaseRequests'))
);
const ProcurementPreferences = Loadable(
  lazy(() => import('views/procurement/Preferences'))
);

export const ProcurementRoutes = [
  {
    path: `${ALL_APPS.PROCUREMENT.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{
          app: ALL_APPS.PROCUREMENT.id,
        }}
      >
        <Navigate to={`${ALL_VIEWS.PROCUREMENT_DASHBOARD.path}`} replace />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PROCUREMENT_DASHBOARD.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
      >
        <ProcurementDashboard />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PURCHASE_REQUESTS.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
      >
        <ProcurementPurchaseRequests />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PROCUREMENT_PREFERENCES.path}`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
      >
        <ProcurementPreferences />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PURCHASE_ORDERS.path}`,
    element: (
      <FullHeightMainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
        >
          <ProcurementPurchaseOrders />
        </PermissionsRouteWrapper>
      </FullHeightMainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PURCHASE_ORDERS.path}/:purchaseId`,
    element: (
      <PermissionsRouteWrapper
        permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
      >
        <ProcurementPurchaseOrder />
      </PermissionsRouteWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PROCUREMENT_APP_INVOICES.path}`,
    element: (
      <FullHeightMainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
        >
          <ProcurementInvoices />
        </PermissionsRouteWrapper>
      </FullHeightMainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PROCUREMENT_APP_INVOICES.path}/:invoiceId`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
        >
          <ProcurementInvoice />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PROCUREMENT_APP_PACKING_SLIPS.path}`,
    element: (
      <FullHeightMainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
        >
          <ProcurementPackingSlips />
        </PermissionsRouteWrapper>
      </FullHeightMainCardWrapper>
    ),
  },
  {
    path: `${ALL_VIEWS.PROCUREMENT_APP_PACKING_SLIPS.path}/:packingSlipId`,
    element: (
      <MainCardWrapper>
        <PermissionsRouteWrapper
          permissionScope={{ app: ALL_APPS.PROCUREMENT.id }}
        >
          <ProcurementPackingSlip />
        </PermissionsRouteWrapper>
      </MainCardWrapper>
    ),
  },
];
